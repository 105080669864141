import React from 'react';
import ReactDOM from 'react-dom/client';
// import { BrowserRouter } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import App from './App';
import ScrollToTop from "./components/ScrollToTop";

const root = ReactDOM.createRoot(document.querySelector('#root'));
root.render( 
    <HashRouter>
        <ScrollToTop />
        <App />
    </HashRouter>, 
);